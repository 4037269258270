/*
 * @Description: 文件上传接口
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-25 21:46:52
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-25 17:37:22
 */
import request from '@/request/request'

export function uploadSign(data) {
    return request({
        url: '/File/uploadSign',
        method: 'post',
        data
    })
}
//上传工单图片和视频
export function uploadOrderFile(data) {
    return request({
        url: '/File/uploadOrderFile',
        method: 'post',
        data
    })
}